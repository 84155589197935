<template>
  <div>
    <div>
      <div>
        <span class="text-color"> <b>Payer &nbsp</b> </span>
        <span> Select the Payer</span>
      </div>
      <v-select v-model="form.payersId" :items="PayersList" item-text="companyName" item-value="id"
        :menu-props="{ maxHeight: '400' }" label="Payers" persistent-hint></v-select>
    </div>
    <div>
      <div>
        <span class="text-color"> <b>Client &nbsp</b> </span>
        <span>Select the Client and leave blank for all</span>
      </div>
      <div>
        <v-autocomplete v-model="form.clientsId" :items="ClientsList" item-text="name" item-value="id" label="Clients"
          persistent-hint></v-autocomplete>
      </div>
    </div>
    <div>
      <div>
        <span class="text-color"> <b>Service Period</b> </span> <br />
        <span>Select the Service period for this batch </span>
      </div>
      <date-range-picker ref="picker" class="py-3" open="center" :max-date="computedDate" v-model="form.dates"
        :ranges="false" prepend-icon="mdi-bell">
        <div slot="footer" slot-scope="data" class="slot">
          <div class="float-right mb-5">
            <span v-if="data.rangeText != ' - '">
              {{ data.rangeText }}
            </span>
            <span class="m-4">
              <a @click="clearRange" v-if="!data.in_selection" class="btn btn-success btn-sm">Clear</a>
            </span>
            <span>
              <a @click="data.clickCancel" v-if="!data.in_selection" class="btn btn-success btn-sm">Cancel</a>
            </span>
            <span class="m-4">
              <a @click="data.clickApply" v-if="!data.in_selection" class="btn btn-success btn-sm">Apply</a>
            </span>
          </div>
        </div>
      </date-range-picker>
    </div>

    <div class="float-right mt-10">
      <span>
        <a @click="RESET" class="btn btn-danger btn-sm">Cancel</a>
      </span>
      <span class="m-4">
        <a @click="Apply" class="btn btn-success btn-sm">Export Billing
          <span v-if="loader">
            <v-progress-circular indeterminate color="primary"></v-progress-circular> </span></a>
      </span>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "processrollscreen1",
  components: { DateRangePicker },
  mixins: [validationMixin],
  validations: {
    form: {
      payersId: { required },
    },
  },
  data() {
    return {
      clients: [],
      sharedServices: [],
      datesofmonths: [],
      selectedmonthreport: [],
      form: {
        payersId: null,
        clientsId: null,

        dates: {
          startDate: null,
          endDate: null,
        },
      },

      menu: false,

      loader: false,
    };
  },
  computed: {
    ...mapGetters(["PayersList", "ClientsList", "BillingReport", "BillingReportNew"]),
    computedDate() {
      return new Date(this.form.date).setDate(
        new Date(this.form.date).getDate() - 1
      );
    },
    formatDate2() {
      if (!this.form.date) return null;

      const [year, month, day] = this.form.date.split("-");
      return `${month}/${day}/${year}`;
    },
    ScheduleDate() {
      if (!this.form.date) return null;
      const [year, month, day] = this.form.date.split("-");
      return `${month}/${day}/${year}`;
    },
  },

  mounted() {
    this.$store.dispatch("getClientList").then(() => {
      this.clients = this.ClientsList;
    });
    // this.$store.dispatch("GetSharedServicesData").then((res) => {

    //   this.sharedServices = res;
    // });
    this.$store.dispatch("getPayersList");
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    this.form.dates = {
      startDate: new Date(new Date().setDate(1)),
      endDate: new Date(y, m + 1, 0),
    };
  },
  methods: {
    async ExportBilling() {
      var records = [];

      this.BillingReport.data.forEach((p) => {
        var count = 0;
        records.forEach((s) => {
          if (new Date(p.startTimeDate).getMonth() == s) {
            count++;
          }
        });
        if (count == 0 && records.length >= 0) {
          records.push(new Date(p.startTimeDate).getMonth());
        }
      });

      records = records.sort((a, b) => {
        return a - b;
      });

      var filename = "Billing Summary";
      var data = [];
      var header = "";
      data.push(
        `${this.form.payerName},Billing Summary: ${this.dobformat2(
          this.form.dates.startDate
        )} To: ${this.dobformat2(this.form.dates.endDate)}`
      );

      header = `"Last Name","First Name","UCI","Status","Initial Date","Vendor","Service","Auth #","Start","End","Hrs","Shared Services","Details",${this.selectedMonth(
        new Date(this.form.dates.startDate),
        new Date(this.form.dates.endDate)
      )}`;
      data.push(header);
     
      if (
        new Date(this.form.dates.startDate).getFullYear() ==
        new Date(this.form.dates.endDate).getFullYear()
      ) {
        this.BillingReport.data.forEach((x) => {
          var isEist = false;
          if (data.length > 2) {
            data.forEach((y, z) => {
              if (z > 1) {
                if (
                  y.includes(x.clienLastName) &&
                  y.includes(x.clienFirstName) &&
                  y.includes(x.vendorCode) &&
                  y.includes(x.subCode) &&
                  y.includes(x.authorizationNumber)
                ) {
                  isEist = true;
                  var month = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ];
                  var curentmonth = month[new Date(x.startTimeDate).getMonth()];
                  var montharray = this.selectedmonthreport.findIndex(
                    (x) => x == curentmonth
                  );
                  var obj = y.split(`","`);
                  obj[11] = (x.sharedServicesParty !== null && x.sharedServicesParty !== undefined) ? x.sharedServicesParty : ''
                  if (montharray == 0) {
                    var e = obj[12 + 1].split(",");
                    if (
                      obj[12 + 1].split(",")[montharray].replaceAll(`"`, "") ==
                      ""
                    ) {
                      e = e.map((f, i) => {
                        f = parseFloat(f.replaceAll(`"`, ""));

                        if (i == montharray) {
                          f = 0;
                        }
                        return parseFloat(f);
                      });
                      obj[12 + 1] = `${e.join(",")}`;
                    } else {
                      var t =
                        parseFloat(
                          obj[12 + 1].split(",")[montharray].replaceAll(`"`, "")
                        ) +
                        parseFloat(x.actualHours != null ? x.actualHours : 0);

                      e = e.map((f, i) => {
                        f = parseFloat(f.replaceAll(`"`, ""));

                        if (i == montharray) {
                          f = parseFloat(t);
                        }
                        return parseFloat(f);
                      });
                      obj[12 + 1] = `${e.join(",")}`;
                    }
                  } else {
                    if (x.actualHours != null) {
                      var e = obj[12 + 1].split(",");

                      if (
                        obj[12 + 1]
                          .split(",")
                        [montharray].replaceAll(`"`, "") == ""
                      ) {
                        e = e.map((f, i) => {
                          f = parseFloat(f.replaceAll(`"`, ""));

                          if (i == montharray) {
                            f = 0;
                          }
                          return parseFloat(f);
                        });
                        obj[12 + 1] = `${e.join(",")}`;
                      } else {
                        var t =
                          parseFloat(
                            obj[12 + 1]
                              .split(",")
                            [montharray].replaceAll(`"`, "")
                          ) +
                          parseFloat(x.actualHours != null ? x.actualHours : 0);

                        e = e.map((f, i) => {
                          f = parseFloat(
                            f == ""
                              ? 0
                              : typeof f == "string"
                                ? f.replaceAll(`"`, "")
                                : f
                          );

                          if (i == montharray) {
                            f = parseFloat(t);
                          }
                          return parseFloat(f);
                        });
                        obj[12 + 1] = `${e.join(",")}`;
                      }
                    }
                  }

                  y = obj.join(`","`).toString();
                }
              }

              data[z] = y;
            });
          }
          if (!isEist) {
            var row = `"${x.clienLastName}","${x.clienFirstName}","${x.uci}","${x.payerIsActive
              }","${this.dobformat2(x.joinDate)}","${x.vendorCode}","${x.subCode
              }","${x.authorizationNumber}"," ${this.dobformat2(
                x.authorizeStartDate
              )}","${this.dobformat2(x.authorizeEndDate)}","${x.hours}","${x.sharedServicesParty == null ? "" : x.sharedServicesParty}","${x.usageNotes != null ? x.usageNotes.replaceAll(",", " ") : ""
              }","${this.showHours(x)}"`;

            data.push(row);
          }
        });
      } else {
        this.BillingReport.data.forEach((x) => {
          var isEist = false;
          if (data.length > 2) {
            data.forEach((y, z) => {
              if (z > 1) {
                if (
                  y.includes(x.clienLastName) &&
                  y.includes(x.clienFirstName) &&
                  y.includes(x.vendorCode) &&
                  y.includes(x.subCode) &&
                  y.includes(x.authorizationNumber)
                ) {
                  isEist = true;
                  var obj = y.split(`,`);
                  var count = 0;
                  for (
                    var d = new Date(this.form.dates.startDate);
                    d <= new Date(this.form.dates.endDate);

                  ) {
                    if (
                      d.getFullYear() ==
                      new Date(x.startTimeDate).getFullYear() &&
                      d.getMonth() == new Date(x.startTimeDate).getMonth()
                    ) {
                      if (
                        d.getMonth() <= new Date(x.startTimeDate).getMonth()
                      ) {
                        if (isNaN(parseFloat(obj[12 + 1 + count]))) {
                          obj[12 + 1 + count] = obj[12 + 1 + count].replace(
                            '"',
                            ""
                          );
                        }
                        obj[12 + 1 + count] =
                          parseFloat(obj[12 + 1 + count]) + x.actualHours !=
                            null
                            ? parseFloat(obj[12 + 1 + count]) + x.actualHours
                            : 0;
                      }
                    }
                    d = new Date(d.setMonth(d.getMonth() + 1));
                    count = count + 1;
                  }
                  count = 0;
                  y = obj.toString();
                }
              }

              data[z] = y;
            });
          }
          if (!isEist) {
            var row = `"${x.clienLastName}","${x.clienFirstName}","${x.uci}","${x.payerIsActive
              }","${this.dobformat2(x.joinDate)}","${x.vendorCode}","${x.subCode
              }","${x.authorizationNumber}","${this.dobformat2(
                x.authorizeStartDate
              )}","${this.dobformat2(x.authorizeEndDate)}",
              "${x.hours}","${x.sharedServicesParty == null ? '' : x.sharedServicesParty}","${x.usageNotes ? x.usageNotes.replaceAll(",", " ") : ""
              }","${this.showHoursYearly(
                new Date(this.form.dates.startDate),
                new Date(this.form.dates.endDate),
                x
              )}"`;

            data.push(row);
          }
        });
      }


      var data1 = data[0].split(`,""`);
      var data2 = data[1].split(",");

      var reportHeaders = "";
      data1.forEach((e, i) => {
        if (i == 0) {
          reportHeaders += `<th style="border:1px solid black;font-weight:bold;">${e.split(",")[0]
            }</th>
            <th colspan="3" style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">${e.split(",")[1]
            }</th>`;
        } else if (i == data1.length - 1) {
          e.split(",").forEach((f) => {
            reportHeaders += `<td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">${f}</td>`;
          });
        } else {
          reportHeaders += `<td style="border:1px solid black;font-weight:bold;">${e}</td>`;
        }
      });
      var Headers = "";
      data2.forEach((e) => {
        Headers += `<th style="border:1px solid black;">${e.replaceAll(
          `"`,
          ""
        )}</th>`;
      });
      var rows = "";
      data.forEach((d, i) => {
        if (i > 1) {
          rows += "<tr>";
          d.split(`","`).forEach((e, j) => {
            if (j == d.split(`","`).length - 1) {
              e.split(`,`).forEach((f) => {
                rows += `<td style="border:1px solid black;">${f.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              });
            } else {
              if (e == "Inactive") {
                rows += `<td style="color:black;    background-color: yellow; border:1px solid black;">${e.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              } else if (e == "Pending Termination") {
                rows += `<td style="color:red;    background-color: yellow; border:1px solid black;">${e.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              } else {
                rows += `<td style="border:1px solid black;">${e.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              }
            }
          });
          rows += "</tr>";
        }
      });

      var test = `
      <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
      <head>
      <xml>
      <x:ExcelWorkbook>
      <x:ExcelWorksheets>
      <x:ExcelWorksheet>
      <x:Name>Sheet 1</x:Name>
      <x:WorksheetOptions>
      <x:Print>
      <x:ValidPrinterInfo/>
      </x:Print>
      </x:WorksheetOptions>
      </x:ExcelWorksheet>
      </x:ExcelWorksheets>
      </x:ExcelWorkbook>
      </xml>
      </head>
      <body>
      <table>
      <tr>
        ${reportHeaders}
      </tr>
  <tr>
    ${Headers}

  </tr>
  ${rows}
</table>
</body>
</html>`;

      this.downloadCSVFile2(test, filename);
    },
    async ExportBillingNew() {


      var filename = `${this.BillingReportNew.data.output.data[0].vendorCode}_${this.BillingReportNew.data.output.data[0].subCode}_${this.excelFormat(this.form.dates.startDate)}`;
      var data = [];
      var header = "";

      data.push(
        `${this.form.payerName},Billing Summary: ${this.dobformat2(
          this.form.dates.startDate
        )} To: ${this.dobformat2(this.form.dates.endDate)}`
      );
      const startDate = new Date(this.form.dates.startDate);
        const endDate = new Date(this.form.dates.endDate);
        // Initialize an array to store months
        const monthsArray = [];
        // Loop through each month from start to end
        let currentDate = startDate;
        while (currentDate <= endDate) {
          // Extract the month and add it to the array
          monthsArray.push(currentDate.toLocaleString('default', { month: 'long' }));
          // Move to the next month
          currentDate.setMonth(currentDate.getMonth() + 1);
        }
      header = `"Last Name","First Name","UCI","Status","Initial Date","Vendor","Service","Auth #","Start","End","Hrs","Shared Services","Details",${this.selectedMonth(
        new Date(this.form.dates.startDate),
        new Date(this.form.dates.endDate)
      )}`;
      data.push(header);
      this.BillingReportNew.data.output.data.forEach((x) => {
        if(x.payerIsActive != "Terminated"){

       
        var row = `"${x.lastName}","${x.firstName}","${x.uci}","${x.payerIsActive
          }","${this.dobformat2(x.joinDate)}","${x.vendorCode}","${x.subCode
          }","${x.authorizationNumber}"," ${this.dobformat2(
            x.start
          )}","${this.dobformat2(x.end)}","${x.hours}","${x.sharedServices}","${x.details != null ? x.details.replaceAll(",", " ") : ""
          }"`;

        
        monthsArray.forEach((month) => {
          if (x[month.toLowerCase()] !== undefined && x[month.toLowerCase()] !== null) {
            row += `,"${x[month.toLowerCase()]}"`;
          }
        });
        data.push(row);
      }
      });



      var data1 = data[0].split(`,""`);
      var data2 = data[1].split(",");

      var reportHeaders = "";
      data1.forEach((e, i) => {
        if (i == 0) {
          reportHeaders += `<th style="border:1px solid black;font-weight:bold;">${e.split(",")[0]
            }</th>
            <th colspan="3" style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">${e.split(",")[1]
            }</th>`;
        } else if (i == data1.length - 1) {
          e.split(",").forEach((f) => {
            reportHeaders += `<td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">${f}</td>`;
          });
        } else {
          reportHeaders += `<td style="border:1px solid black;font-weight:bold;">${e}</td>`;
        }
      });
      var Headers = "";
      data2.forEach((e) => {
        Headers += `<th style="border:1px solid black;">${e.replaceAll(
          `"`,
          ""
        )}</th>`;
      });
      var rows = "";
      data.forEach((d, i) => {
        if (i > 1) {
          rows += "<tr>";
          d.split(`","`).forEach((e, j) => {
            if (j == d.split(`","`).length - 1) {
              e.split(`,`).forEach((f) => {
                rows += `<td style="border:1px solid black;">${f.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              });
            } else {
              if (e == "Inactive") {
                rows += `<td style="color:black;    background-color: yellow; border:1px solid black;">${e.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              } else if (e == "Pending Termination") {
                rows += `<td style="color:red;    background-color: yellow; border:1px solid black;">${e.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              } else {
                rows += `<td style="border:1px solid black;">${e.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              }
            }
          });
          rows += "</tr>";
        }
      });

      var test = `
      <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
      <head>
      <xml>
      <x:ExcelWorkbook>
      <x:ExcelWorksheets>
      <x:ExcelWorksheet>
      <x:Name>Sheet 1</x:Name>
      <x:WorksheetOptions>
      <x:Print>
      <x:ValidPrinterInfo/>
      </x:Print>
      </x:WorksheetOptions>
      </x:ExcelWorksheet>
      </x:ExcelWorksheets>
      </x:ExcelWorkbook>
      </xml>
      </head>
      <body>
      <table>
      <tr>
        ${reportHeaders}
      </tr>
  <tr>
    ${Headers}

  </tr>
  ${rows}
</table>
</body>
</html>`;

      this.downloadCSVFile2(test, filename);
    },
    downloadCSVFile2(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;
      var base64data = "base64," + btoa(csv);
      //window.open('data:application/vnd.ms-'+defaults.type+';filename=exportData.doc;' + base64data); //this was original

      // Now the little tricky part.(By shreedhar)
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension
      var uri = "data:application/vnd.ms-excel;" + base64data;

      // download_link.href = window.URL.createObjectURL(csv_file);
      download_link.href = uri;

      download_link.style = "visibility:hidden";

      document.body.appendChild(download_link);

      download_link.click();
    },
    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
    ActualHours(obj) {
      var o = this.BillingReport.hours.find((x) => x.clientId === obj.clientID);

      return o.hours;
    },
    clearRange() {
      this.form.dates = {
        startDate: null,
        endDate: null,
      };
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    selectedDates() {
      return this.datesofmonths.toString();
    },
    showHours(obj) {
      var month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var hoursData = [];
      var curentmonth = month[new Date(obj.startTimeDate).getMonth()];
      var montharray = this.selectedmonthreport.findIndex(
        (x) => x == curentmonth
      );
      this.selectedmonthreport.forEach((y, z) => {
        if (montharray == z) {
          hoursData.push(obj.actualHours);
        } else {
          hoursData.push(0);
        }
      });
      return hoursData.toString();
    },
    showHoursYearly(sobj, eobj, obj) {
      var hoursData = [];
      for (var d = new Date(sobj); d <= new Date(eobj);) {
        if (d.getFullYear() == new Date(obj.startTimeDate).getFullYear()) {
          if (d.getMonth() == new Date(obj.startTimeDate).getMonth()) {
            hoursData.push(obj.actualHours);
          } else {
            hoursData.push(0);
          }
        } else {
          hoursData.push(0);
        }
        d = new Date(d.setMonth(d.getMonth() + 1));
      }
      return hoursData.toString();
    },
    selectedMonth(s, e) {
      var month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var datemont = [];
      if (s.getFullYear() == e.getFullYear()) {
        var datemont = [];
        var selectedmonths = month.slice(s.getMonth(), e.getMonth() + 1);
        selectedmonths.forEach(function (x) {
          datemont.push(0);
        });
        this.selectedmonthreport = selectedmonths;
        this.datesofmonths = datemont;
        return selectedmonths.toString();
      } else {
        for (var d = new Date(s); d <= new Date(e);) {
          datemont.push(month[d.getMonth()] + " " + d.getFullYear());

          d = new Date(d.setMonth(d.getMonth() + 1));
        }
        this.datesofmonths = datemont;
        return datemont.toString();
      }
    },
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
      return ""
    },
    excelFormat(obj) {
      if (obj) {
        var date = new Date(obj);
        var monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        var month = monthNames[date.getMonth()];
        var year = date.getFullYear();
        return `${month}${year}`;
      }
    },
    async Apply() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.loader = true;
        var startDate;
        var endDate;
        if (this.form.dates.startDate) {
          startDate = this.dobformat2(this.form.dates.startDate);
        }
        if (this.form.dates.endDate) {
          endDate = this.dobformat2(this.form.dates.endDate);
        }

        await this.$store
          .dispatch("getNewBillingReports", {
            payers: this.form.payersId,
            clients: this.form.clientsId,
            startdate: startDate,
            enddate: endDate,
          })
          .then((response) => {
            this.isfetching = false;


            if (response.data.output.data.length > 0) {
              this.form.payerName = this.PayersList.filter(
                (x) => x.id == this.form.payersId
              )[0].companyName;
              this.ExportBillingNew();
              // this.ExportBilling();
              this.loader = false;
              this.$bvModal.hide("filter");
            } else {
              this.loader = false;
              Swal.fire({
                title: "",
                text: "No Record Found",
                icon: "error",
                confirmButtonClass: "btn btn-danger",
              });
            }
          })
          .catch((ex) => {
            this.isfetching = false;
          });
      }
    },
    RESET() {
      (this.form.batchName = []),
        (this.form.date = []),
        (this.form.dates = {}),
        this.$bvModal.hide("filter");
    },
  },
};
</script>

<style>
.v-date-picker-table .v-btn.v-btn--active {
  color: #2d79bd !important;
}

.v-btn--outlined {
  border: thin solid #2d79bd !important;
}

.text-color {
  color: #2d79bd;
}

.reportrange-text[data-v-1ebd09d2] {
  background: #fff;
  cursor: pointer;
  padding: 10px 10px;
  border: 1px solid #ccc;
  width: 100%;
  overflow: hidden;
  font-size: 15px;
}

.vue-daterange-picker[data-v-1ebd09d2] {
  display: block;
}

.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
</style>